<template>
  <div class="product">
    <Header/>
    <div class="banner">
      <img src="../assets/images/product-banner.jpg" alt="">
    </div>
    <section class="main product-area prduct-page" title="Carpet Tile">
      <div class="container">
        <div class="section-heading">
          <h2>Our <span>Products</span></h2>
          <p>We carry products from several major brands that we've selected to offer you a wide array of options. We're certain that you'll find the perfect floor for your home.</p>
        </div>
        <div class="product-inner">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-6">
              <router-link to="/page" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/pd1.jpg" alt="">
                </div>
                <div class="product-text"><h6>Flooring</h6></div>
              </router-link>
            </div>
            <div class="col-xl-4 col-6">
              <router-link to="/page" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/pd2.jpg" alt="">
                </div>
                <div class="product-text"><h6>Walls</h6></div>
              </router-link>
            </div>
            <div class="col-xl-4 col-6">
              <router-link to="/page" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/pd3.jpg" alt="">
                </div>
                <div class="product-text"><h6>Countertops</h6></div>
              </router-link>
            </div>
            <div class="col-xl-4 col-6">
              <router-link to="/page" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/pd4.jpg" alt="">
                </div>
                <div class="product-text"><h6>Fireplace</h6></div>
              </router-link>
            </div>
            <div class="col-xl-4 col-6">
              <router-link to="/page" class="product-box">
                <div class="product-img-box">
                  <img src="../assets/images/pd5.jpg" alt="">
                </div>
                <div class="product-text"><h6> Window </h6></div>
              </router-link>
            </div>
            
          </div>
        </div>
        
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Product',
  components: {
    Header,
    Footer
  }
}
</script>
