<template>
  <div class="about">
    <Header/>
    <div class="aboutContentSection">
      <div class="banner inner-banner">
        <img src="../assets/images/about-banner.jpg" alt="">
      </div>
      <section class="inner-main">
        <div class="container">
          <div class="story-sec">
            <div class="story-sec-img">
              <img src="../assets/images/story-img.jpg" alt="">
            </div>
            <div class="story-sec-text">
              <h3>Our <span>Story</span></h3>
              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
              <p>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default.</p>
              <a href="#rqst-form" class="common-midium-button inner-common-midum-button">Learn More </a>
            </div>
          </div>
        </div>
      </section>
      <section class="inner-main pt-0">
        <div class="container">
          <div class="story-sec who-we-are-sec">
            <div class="story-sec-img">
              <img src="../assets/images/who-sec1.jpg" alt="">
              <img src="../assets/images/who-sec2.jpg" alt="" class="who-sec-abs">
            </div>
            <div class="story-sec-text">
              <h3><span>Who</span> We Are</h3>
              <p>It is a long established fact that a reader will be distracted by the readable content page when looking at its layout. The point of using Lorem Ipsum is reader.</p>
              <div class="who-li-sec">
                <ul>
                  <li>Making it look like readable English. Many packages and web page editors now their default.</li>
                  <li>Making it look like readable English. Many packages and web page editors now their default.</li>
                  <li>Making it look like readable English. Many packages and web page editors now their default.</li>
                  <li>Making it look like readable English. Many packages and web page editors now their default.</li>
                </ul>
                <router-link to="/contact" class="common-midium-button inner-common-midum-button">Contact Now</router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="get-professional">
          <div class="container">
            <div class="get-professional-iner">
              <p>Get Professional Advice</p>
              <a href="tel:+1-458-362-1258" class="tell-us-now">Call us Now. +1-458-362-1258</a>
            </div>
          </div>
        </div>
      </section>
      <section class="inner-main faq-sec">
        <div class="container">
          <div class="inner-page-heading">
            <h2>Frequently Asked <span>Questions</span></h2>
          </div>
          <div class="faq-box">
            <div class="faq-heading">
              <h6>Q. Lorem ipsum dolor sit amet, Quisque fermentum ante et metus euismod volutpat?</h6>
              <span class="faq-controller"> <i class="fas fa-caret-down"></i> </span>
            </div>
            <div class="faq-content">
              <p>Phasellus id leo vel leo tempor maximus. Morbi mollis ex a augue posuere, non laoreet quam eleifend. Sed efficitur eget ex nec egestas.Sed placerat, justo vitae auctor sagittis, risus libero porttitor justo, tempus suscipit leo risus non erat. Integer eget magna ut dolor varius. Donec placerat ipsum sit amet enim dapibus vestibulum. Suspendisse ornare magna vitae sem lacinia, eget posuere nunc aliquam </p>
            </div>
          </div>
          <div class="faq-box">
            <div class="faq-heading">
              <h6>Q. Lorem ipsum dolor sit amet, Quisque fermentum ante et metus euismod volutpat?</h6>
              <span class="faq-controller"> <i class="fas fa-caret-down"></i> </span>
            </div>
            <div class="faq-content">
              <p>Phasellus id leo vel leo tempor maximus. Morbi mollis ex a augue posuere, non laoreet quam eleifend. Sed efficitur eget ex nec egestas.Sed placerat, justo vitae auctor sagittis, risus libero porttitor justo, tempus suscipit leo risus non erat. Integer eget magna ut dolor varius. Donec placerat ipsum sit amet enim dapibus vestibulum. Suspendisse ornare magna vitae sem lacinia, eget posuere nunc aliquam </p>
            </div>
          </div>
          <div class="faq-box">
            <div class="faq-heading">
              <h6>Q. Lorem ipsum dolor sit amet, Quisque fermentum ante et metus euismod volutpat?</h6>
              <span class="faq-controller"> <i class="fas fa-caret-down"></i> </span>
            </div>
            <div class="faq-content">
              <p>Phasellus id leo vel leo tempor maximus. Morbi mollis ex a augue posuere, non laoreet quam eleifend. Sed efficitur eget ex nec egestas.Sed placerat, justo vitae auctor sagittis, risus libero porttitor justo, tempus suscipit leo risus non erat. Integer eget magna ut dolor varius. Donec placerat ipsum sit amet enim dapibus vestibulum. Suspendisse ornare magna vitae sem lacinia, eget posuere nunc aliquam </p>
            </div>
          </div>
          <div class="faq-box">
            <div class="faq-heading">
              <h6>Q. Lorem ipsum dolor sit Quisque fermentum metus euismod volutpat?</h6>
              <span class="faq-controller"> <i class="fas fa-caret-down"></i> </span>
            </div>
            <div class="faq-content">
              <p>Phasellus id leo vel leo tempor maximus. Morbi mollis ex a augue posuere, non laoreet quam eleifend. Sed efficitur eget ex nec egestas.Sed placerat, justo vitae auctor sagittis, risus libero porttitor justo, tempus suscipit leo risus non erat. Integer eget magna ut dolor varius. Donec placerat ipsum sit amet enim dapibus vestibulum. Suspendisse ornare magna vitae sem lacinia, eget posuere nunc aliquam </p>
            </div>
          </div>
          <div class="faq-box">
            <div class="faq-heading">
              <h6>Q. Lorem ipsum dolor sit Quisque fermentum metus euismod volutpat?</h6>
              <span class="faq-controller"> <i class="fas fa-caret-down"></i> </span>
            </div>
            <div class="faq-content">
              <p>Phasellus id leo vel leo tempor maximus. Morbi mollis ex a augue posuere, non laoreet quam eleifend. Sed efficitur eget ex nec egestas.Sed placerat, justo vitae auctor sagittis, risus libero porttitor justo, tempus suscipit leo risus non erat. Integer eget magna ut dolor varius. Donec placerat ipsum sit amet enim dapibus vestibulum. Suspendisse ornare magna vitae sem lacinia, eget posuere nunc aliquam </p>
            </div>
          </div>
          <div class="faq-box">
            <div class="faq-heading">
              <h6>Q. Lorem ipsum dolor sit Quisque fermentum metus euismod volutpat?</h6>
              <span class="faq-controller"> <i class="fas fa-caret-down"></i> </span>
            </div>
            <div class="faq-content">
              <p>Phasellus id leo vel leo tempor maximus. Morbi mollis ex a augue posuere, non laoreet quam eleifend. Sed efficitur eget ex nec egestas.Sed placerat, justo vitae auctor sagittis, risus libero porttitor justo, tempus suscipit leo risus non erat. Integer eget magna ut dolor varius. Donec placerat ipsum sit amet enim dapibus vestibulum. Suspendisse ornare magna vitae sem lacinia, eget posuere nunc aliquam </p>
            </div>
          </div>
          <div class="still-sec">
            <h3>Still Have a Questions?</h3>
            <p>If you cannot find answer to your question in our FAQ, you can always
            <router-link to="/contact"> contact us</router-link>. We will answer to you shortly!</p>
          </div>
        </div>
      </section>
      <Testimonials/>
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Testimonials from '../components/Testimonials.vue'

import $ from "jquery";

export default {
  name: 'About',
  components: {
    Header,
    Footer,
    Testimonials
  },
   mounted: function() {
      window.setTimeout(function() {
          $(".faq-box").first().find(".faq-content").slideDown();
          $(".faq-controller").click(function(){
            $(".faq-box").removeClass("faq-active").find(".faq-content").not().stop().slideUp();
            $(this).closest(".faq-box").addClass("faq-active").find(".faq-content").stop().slideDown();
          });
      }, 1000);
  }
}
</script>

