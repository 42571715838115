<template>
  <div class="page">
    <Header/>
    <div class="banner inner-banner">
      <img src="../assets/images/content-banner.jpg" alt="">
    </div>
    <section class="inner-main purussit-sec">
      <div class="container">
          <div class="inner-page-heading">
            <h2>Cras mattis Consectetur <span>Purussit</span></h2>
          <h6>We <span>Offer Several Services</span> to help you complete your project from start to finish</h6>
        </div>
        <p>Nulla sed lectus eleifend mi fermentum vulputate ac quis velit. Curabitur ex sapien, accumsan iaculis facilisis id, eleifend id nunc. Aliquam erat volutpat. Nunc  elementum erat non nisi malesuada accumsan. Etiam eu ipsum nibh. Proin ultricies purus imperdiet lacinia convallis. Nulla facilisi. Proin cursus finibus felis,  efficitur sollicitudin augue. Nulla vehicula interdum nunc, non mattis enim tempus a. Aliquam convallis nulla justo, sit amet bibendum eros consectetur in. Lutte elit tellus sit luctus nec purusDonec id elit non mi porta gravida at eget metus. Nulla vitae elit libero, a pharetra augue. Morbi leo risus,Proin in quam euismod ipsum porttitor accumsan eu eu nisl. Donecvestibulum viverra orci, vitae varius ligula ullamcorper ut. </p>

        <p>Morbi condimentum ullamcorper leo, eu vestibulum nunc lacinia vitae. Proin in quam euismod ipsum porttitor accumsan eu eu nisl. Donecvestibulum viverra orci, vitae varius ligula ullamcorper ut. Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>	
    </div>
    <div class="service-list">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  class="service-list-box" data-bs-toggle="modal" data-bs-target="#myModal1">
            <img src="../assets/images/sr1.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                <div class="servie-cn">
                  <p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p>
                </div>
              </div>
            </div>
            <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  class="service-list-box" data-bs-toggle="modal" data-bs-target="#myModal2">
            <img src="../assets/images/sr2.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
              </div>
              <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#myModal3"  class="service-list-box" >
            <img src="../assets/images/sr3.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
              </div>
              <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  data-bs-toggle="modal" data-bs-target="#myModal4"  class="service-list-box">
            <img src="../assets/images/sr4.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
              </div>
              <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  data-bs-toggle="modal" data-bs-target="#myModal5"  class="service-list-box">
            <img src="../assets/images/sr5.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
              </div>
              <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  data-bs-toggle="modal" data-bs-target="#myModal6"  class="service-list-box">
            <img src="../assets/images/sr6.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
              </div>
              <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  data-bs-toggle="modal" data-bs-target="#myModal7"  class="service-list-box">
            <img src="../assets/images/sr7.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
            </div>
            <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  data-bs-toggle="modal" data-bs-target="#myModal8"  class="service-list-box">
            <img src="../assets/images/sr8.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
            </div>
            <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
        <div class="col-lg-4 col-md-6">
          <a href="javascript:void(0);"  data-bs-toggle="modal" data-bs-target="#myModal9"  class="service-list-box">
            <img src="../assets/images/sr9.jpg" alt="">
            <div class="servc-txt">
              <div class="srv-txt-inr">
                <h5>Nullam Sagittis</h5>
                  <div class="servie-cn"><p>Finibus ipsum eget venenatis enim Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, ratione, vel! Officia voluptate corrupti at nobis error? Iusto animi modi eos sequi esse sapiente officiis, non, voluptas in distinctio, error!</p></div>
              </div>
            </div>
            <div class="search-icn-srvc"><i class="fas fa-search"></i></div>
            <div class="bdrbx"></div>
          </a>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr1.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal two -->
    <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr2.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal three -->
    <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr3.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal four -->
    <div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr4.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal five -->
    <div class="modal fade" id="myModal5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr5.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal six -->
    <div class="modal fade" id="myModal6" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr6.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal seven -->
    <div class="modal fade" id="myModal7" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr7.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal three -->
    <div class="modal fade" id="myModal8" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr8.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  <button type="button" class="btn btn-default btn-next"><i class="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal nine -->
    <div class="modal fade" id="myModal9" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div>
            <div class="modal-img">
              <img src="../assets/images/sr9.jpg" alt="">
            </div>
            <div class="modal-text">
              <h5>Nullam Sagittisbr</h5>
              <p>Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat. consectetur adipisicing, elit. Vero aut, repellendus excepturi dolores illo et ab assumenda dicta aspernatur enim eos dolorem blanditiis esse id ipsum labore earum reiciendis quaerat.Finibus ipsum eget venenatis enim lorem,Lorem ipsum dolor sit amet, consectetur adipisicing, elit. Vero aut, </p>
            </div>
            <button type="button" class="btn btn-default btn-prev"><i class="fas fa-chevron-left"></i></button>
                  
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="text-center butn-grp">

    <a href="#rqst-form" class="get-nw">Contact Now</a>
    </div>
    </section>
    <Footer/>
  </div>
</template>



<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'


import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

export default {
  name: 'Page',
  components: {
    Header,
    Footer,
  },
  mounted: function(){
    
  $("div[id^='myModal']").each(function(){
      var currentModal = window.$(this);
        currentModal.find('.btn-next').click(function(){
        currentModal.modal('hide');
        currentModal.closest("div[id^='myModal']").nextAll("div[id^='myModal']").first().modal('show'); 
      });
      currentModal.find('.btn-prev').click(function(){
        currentModal.modal('hide');
        currentModal.closest("div[id^='myModal']").prevAll("div[id^='myModal']").first().modal('show'); 
      });
    });
    $('.modal').on('shown.bs.modal', function () {
      $('body').addClass('modal-open');
    })

  }
}
</script>

