<template>
  <div class="service">
    <Header/>
    <div class="banner inner-banner">
      <img src="../assets/images/service-banner.jpg" alt="">
    </div>
    <section class="main service-first">
      <div class="container">
        <div class="inner-page-heading">
        <h2>Our Experts Offer a <span>Variety of Services</span></h2>
        <p>We <span>Offer Several Services</span> to help you complete your project from start to finish</p>
        </div>
      </div>
      <div class="services-inner services-inner-first">
        <div class="container">
          <div class="service-pg-img">
            <img src="../assets/images/svp1.jpg" alt="">
          </div>
          <div class="service-pg-text">
            <h3>Installation</h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, <br> content here.</p>
            <p>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as <br> their default.</p>
          </div>
        </div>
      </div>
      <div class="services-inner">
        <div class="container">
          <div class="service-pg-img">
            <img src="../assets/images/svp2.jpg" alt="">
          </div>
          <div class="service-pg-text">
            <h3>In-Home Estimates</h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, <br> content here.</p>
            <p>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as <br> their default.</p>
          </div>
        </div>
      </div>
    <div class="services-inner">
      <div class="container">
        <div class="service-pg-img">
          <img src="../assets/images/svp3.jpg" alt="">
        </div>
        <div class="service-pg-text">
          <h3>Commercial Flooring</h3>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, <br> content here.</p>
          <p>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as <br> their default.</p>
        </div>
      </div>
    </div>
    <div class="services-inner">
      <div class="container">
        <div class="service-pg-img">
          <img src="../assets/images/svp4.jpg" alt="">
        </div>
        <div class="service-pg-text">
          <h3>Cleaning</h3>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, <br> content here.</p>
          <p>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as <br> their default.</p>
        </div>
      </div>
    </div>
    <div class="services-inner">
      <div class="container">
        <div class="service-pg-img">
          <img src="../assets/images/svp5.jpg" alt="">
        </div>
        <div class="service-pg-text">
          <h3>Design Consultation</h3>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, <br> content here.</p>
          <p>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as <br> their default.</p>
        </div>
      </div>
    </div>


    </section>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Service',
  components: {
    Header,
    Footer
  }
}
</script>
