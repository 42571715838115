<template>
  <div class="contact">
    <Header/>
    <div class="banner inner-banner">
      <img src="../assets/images/contact-banner.jpg" alt="">
    </div>
    <section class="contact-content">
      <div class="container">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Donec id elit non mi porta gravida at eget metus. Nulla vitae elit libero, a pharetra augue. </p>
      </div>
    </section>
    <section class="select-your-location">
      <div class="container">
        <div class="select-your-location-inner">
          <div class="select-your-location-box">
            <h6>Our Popular Locations </h6>
            <h2>Select Your Location To <span>Get Started</span></h2>
            <ul>
              <li data-id="1" data-tag="01" class="adress-li-active"><a href="javascript:void(0);">New York</a></li>
              <li data-id="2" data-tag="02"><a href="javascript:void(0);">Florida</a></li>
              <li data-id="3" data-tag="03"><a href="javascript:void(0);">Boston</a></li>
              <li data-id="4" data-tag="04"><a href="javascript:void(0);">Washington.D.c.</a></li>
              <li data-id="5" data-tag="05"><a href="javascript:void(0);">Miami</a></li>
              <li data-id="6" data-tag="06"><a href="javascript:void(0);">Ohio</a></li>
              <li data-id="7" data-tag="07"><a href="javascript:void(0);">San Fransisco</a></li>
              <li data-id="8" data-tag="08"><a href="javascript:void(0);">New Jersey</a></li>
              <li data-id="9" data-tag="09" class="no-bdr mbl-bdr"><a href="javascript:void(0);">Toronto</a></li>
              <li data-id="10" data-tag="100" class="no-bdr"><a href="javascript:void(0);">Ontario</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="main request-section contact-page-sec" id="myDiv">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-xl-5">
            <div class="request-lft-sec">
              <h3>Contact Info</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. </p>
              <div class="adress-rea-sec" id="1">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    New York, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="2">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Florida, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="3">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Boston, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="4">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Washington.D.c., lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="5">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Miami, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="6">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Ohio, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="7">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    San Fransisco, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="8">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    New Jersey, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="9">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Toronto, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
              <div class="adress-rea-sec" id="10">
                <div class="area-flex-sec-mbl">
                  <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Ontario, lorem ipsum,
                        CA,USAMagetsham 19
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="tel:+1-458-362-1258">+1-458-362-1258</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="fas fa-envelope"></i></span>
                  </div>
                  <div class="addpg-txt">
                    <a href="mailto:+1-458-362-1258">Contact@yoursite.web</a>
                  </div>
                </div>
                <div class="add-pagsec">
                  <div class="addpg-icn">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="addpg-txt">
                    Sun - Sat 07:00 - 21:00
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="request-rgt-sec" id="rqst-form">
              <h3>Request Estimate</h3>
              <p>Let one of our experts help your find the perfect floor</p>
              <form action="">
                <div class="two-row">
                  <div class="frm-input">
                    <input type="text" placeholder="Your Name:" required>
                  </div>
                  <div class="frm-input">
                    <input type="email" placeholder=" Your Email:" required>
                  </div>
                </div>
                <div class="thrd-row">
                  <div class="frm-input">
                    <input type="text" placeholder="Your Telephone No:">
                  </div>
                  <div class="thrd-row-dinr">
                    <div class="frm-input">
                    <input type="text" placeholder="City:">
                    </div>
                    <div class="frm-input">
                      <input type="text" placeholder=" State:">
                    </div>
                  </div>
                </div>
                <div class="one-row">
                  <div class="frm-input">
                    <textarea placeholder="Tell Us What you are Looking For:" required></textarea>
                    </div>
                </div>
                <div class="one-row">
                  <button class="submit" type="submit">Get Estimate <i class="fas fa-paper-plane"></i></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
      <section class="map-area">
        <div class="map-holder map-one" id="01">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.1583091352!2d-74.11976373946234!3d40.69766374859258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1637818522730!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-two" id="02">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7223521.35144656!2d-88.90466283640747!3d27.867890055029754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c1766591562abf%3A0xf72e13d35bc74ed0!2sFlorida%2C%20USA!5e0!3m2!1sen!2sin!4v1637819684410!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-three" id="03">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d94410.11617856793!2d-71.04032357308542!3d42.31445557960823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3652d0d3d311b%3A0x787cbf240162e8a0!2sBoston%2C%20MA%2C%20USA!5e0!3m2!1sen!2sin!4v1637819759643!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-four" id="04">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99370.1418401127!2d-77.08461565052947!3d38.893867189158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20DC%2C%20USA!5e0!3m2!1sen!2sin!4v1637819805628!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-five" id="05">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114964.3894384051!2d-80.29949833445895!3d25.78254531068484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b0a20ec8c111%3A0xff96f271ddad4f65!2sMiami%2C%20FL%2C%20USA!5e0!3m2!1sen!2sin!4v1637819862653!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-six" id="06">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113818.960340516!2d-84.91214206718675!3d40.34592120784555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836e97ab54d8ec1%3A0xe5cd64399c9fd916!2sOhio%2C%20USA!5e0!3m2!1sen!2sin!4v1637819906223!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-seven" id="07">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50470.02846874067!2d-122.4726193794149!3d37.757762678289566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1637820052715!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-eight" id="08">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1563266.1918335108!2d-75.84536893896656!3d40.06973977396647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c0fb959e00409f%3A0x2cd27b07f83f6d8d!2sNew%20Jersey%2C%20USA!5e0!3m2!1sen!2sin!4v1637820116451!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-nine" id="09">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184551.8085843838!2d-79.5181404944258!3d43.71840381067178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sin!4v1637820153491!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="map-holder map-ten" id="100">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10727961.455662023!2d-93.70269682410937!3d48.97002657602706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce05b25f5113af%3A0x70f8425629621e09!2sOntario%2C%20Canada!5e0!3m2!1sen!2sin!4v1637820233509!5m2!1sen!2sin" width="100%" height="678" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </section>
      <section class="inner-main get-started">
        <div class="container">
          <h2>Ready to <span>Get Started?</span></h2>
          <p>Here's everything you need to know when starting your next flooring project.</p>
          <a href="javascript:void(0);" class="common-midium-button get-started-button">Get Started Now </a>
        </div>
      </section>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

import $ from "jquery";

export default {
  name: 'Contact',
  components: {
    Header,
    Footer
  },
  mounted: function(){
    $('.adress-rea-sec').first().show();
    $('.map-holder').first().show();
    $('.select-your-location-box ul').find('li').click(function(){    
      var clkbtn =  $(this).data('id');
      $('.adress-rea-sec').not().stop().hide();
      $('#'+clkbtn).stop().show();
      var clkdt =  $(this).data('tag');
      $('.map-holder').not().stop().hide();
      $('#'+clkdt).stop().show();
    $('.select-your-location-box ul').find('li').not().stop().removeClass('adress-li-active');
    $(this).stop().addClass('adress-li-active');
     $('html, body').animate({
        scrollTop: $("#myDiv").offset().top
    },500);
    });
  }
}
</script>
